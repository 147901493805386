import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import log from 'loglevel';

import Page from '../Layout/Page';
import ClosureDetails from '../../components/AccountingClosure/ClosureDetails';
import GroupedClosureList from '../../components/AccountingClosure/GroupedClosureList';
import Loading from '../../components/Loading';
import { LOAD_STATUS } from '../../components/constants';

import { getClosure } from '../../rest/resource/closure';

const logger = log.getLogger('AccountingClosureDetails');

const AccountingClosureDetails = () => {
  const { formatMessage, formatDate, locale } = useIntl();
  const { id: closureId } = useParams();

  const [closure, setClosure] = useState();
  const [loadStatus, setLoadStatus] = useState(LOAD_STATUS.LOADING);

  useEffect(() => {
    (async () => {
      try {
        const closureResponse = await getClosure(closureId);
        setClosure(closureResponse);
        setLoadStatus(LOAD_STATUS.SUCCESS);
      } catch (err) {
        logger.error('Failed to load closure.', err);
        setLoadStatus(LOAD_STATUS.ERROR);
      }
    })();
  }, [closureId]);

  const pageTitle = useMemo(
    () =>
      closure
        ? `${formatMessage({ id: 'accounting.closure.title' })} - 
        ${formatDate(closure.closureDate)}`
        : formatMessage({ id: 'accounting.closure.title' }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closure, locale]
  );

  const breadcrumbs = closure
    ? [
        { label: formatMessage({ id: 'accounting.closure.title' }), ref: '/accounting/closure' },
        { label: formatDate(closure.closureDate), ref: `/accounting/closure/${closureId}` }
      ]
    : [{ label: formatMessage({ id: 'accounting.closure.title' }), ref: '/accounting/closure' }];

  const renderContent = () => {
    switch (loadStatus) {
      case LOAD_STATUS.LOADING:
        return <Loading />;
      case LOAD_STATUS.SUCCESS: {
        const { systemClosure, ...details } = closure;
        return (
          <>
            <ClosureDetails closure={details} />
            <GroupedClosureList closureId={closureId} closureBySystem={systemClosure} />
          </>
        );
      }
      default:
        return (
          <div className="closure-list-message">
            <FormattedMessage id="accounting.closure.list.error" />
          </div>
        );
    }
  };

  return (
    <Page className="closure-page" title={pageTitle} breadcrumbs={breadcrumbs} back>
      {renderContent()}
    </Page>
  );
};

export default AccountingClosureDetails;
