import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  IconButton,
  OutlinedInput
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import classnames from 'classnames';

import FormSection from '../FormSection';

const URL_PATTERN =
  /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i;

const SAVE_ERROR = {
  CONFLICT: 'CONFLICT',
  GENERIC: 'GENERIC'
};

const systemFormFields = {
  id: { max: 64 },
  url: { max: 2048 },
  name: { max: 500 },
  description: { max: 5000 }
};

const invoiceFormFields = {
  bankName: { max: 500 },
  bankAccountNumber: { max: 34 },
  username: { max: 50 },
  password: { max: 50 },
  invoiceDescription: { max: 5000 }
};

const _mapSystemForm = (initialValues = {}) =>
  Object.keys(systemFormFields).reduce((result, current) => {
    result[current] = initialValues[current] || '';
    return result;
  }, {});

const _mapInvoiceForm = (initialValues = {}) => ({
  bankName: initialValues.bankName || '',
  bankAccountNumber: initialValues.bankAccountNumber || '',
  invoiceDescription: initialValues.description || '',
  username: initialValues.username || '',
  password: initialValues.password || ''
});

const validateUrlFormat = string => {
  try {
    return URL_PATTERN.test(string);
  } catch (_) {
    return false;
  }
};

const validateFormData = (formData, invoiceData) => {
  const systemSettingsData = Object.entries(formData).reduce(
    (result, [key, value]) => {
      if (!value) {
        result.fields[key] = 'required';
      } else if (key === 'url' && !validateUrlFormat(value)) {
        result.fields[key] = 'invalid';
      }

      result.hasError = result.hasError || !!result.fields[key];

      return result;
    },
    {
      hasError: false,
      fields: {}
    }
  );

  const invoiceSettingsData = Object.entries(invoiceData).reduce(
    (result, [key, value]) => {
      if (!value) {
        result.fields[key] = 'required';
      }

      result.hasError = result.hasError || !!result.fields[key];

      return result;
    },
    {
      hasError: false,
      fields: {}
    }
  );

  const fields = { ...systemSettingsData.fields, ...invoiceSettingsData.fields };
  const hasError = systemSettingsData.hasError || invoiceSettingsData.hasError;

  return { fields, hasError };
};

const SystemDialog = ({
  onClose,
  onSave,
  form: initialSystemForm,
  formInvoice: initialInvoiceForm,
  isEdit,
  title
}) => {
  const { formatMessage } = useIntl();

  const [saveError, setSaveError] = useState();
  const [systemForm, setSystemForm] = useState(_mapSystemForm(initialSystemForm));
  const [invoiceForm, setInvoiceForm] = useState(_mapInvoiceForm(initialInvoiceForm));
  const [errors, setErrors] = useState({});
  const [shouldValidate, setShouldValidate] = useState(isEdit);
  const [showPassword, setShowPassword] = useState(false);

  const validate = (formData, invoiceData) => {
    const { fields, hasError } = validateFormData(formData, invoiceData);
    setErrors(fields);
    return hasError;
  };

  useEffect(() => {
    if (shouldValidate) {
      validate(systemForm, invoiceForm);
    }
  }, [invoiceForm, shouldValidate, systemForm]);

  const onSubmit = async () => {
    setShouldValidate(true);

    if (!validate(systemForm, invoiceForm)) {
      try {
        await onSave({
          ...systemForm,
          invoice: {
            bankName: invoiceForm.bankName,
            bankAccountNumber: invoiceForm.bankAccountNumber,
            description: invoiceForm.invoiceDescription,
            username: invoiceForm.username,
            password: invoiceForm.password
          }
        });
        onClose();
      } catch (err) {
        if (err.response?.status === 409) {
          setSaveError(SAVE_ERROR.CONFLICT);
        } else {
          setSaveError(SAVE_ERROR.GENERIC);
        }
      }
    }
  };

  const handleInputChange = ({ target: { name, value } }) => {
    if (saveError) {
      setSaveError();
    }

    const systemFormAndInvoice = { ...systemForm, invoice: invoiceForm };
    const updatedSystemForm = { ...systemFormAndInvoice, [name]: value };
    setSystemForm(updatedSystemForm);
  };

  const handleInvoiceInputChange = ({ target: { name, value } }) => {
    if (saveError) {
      setSaveError();
    }

    const updatedInvoiceForm = { ...invoiceForm, [name]: value };
    setInvoiceForm(updatedInvoiceForm);

    const systemFormAndInvoice = { ...systemForm, invoice: updatedInvoiceForm };
    setSystemForm(systemFormAndInvoice);
  };

  const translateText = key => {
    if (errors[key] === 'required') {
      return formatMessage({ id: 'createSystemDialog.requiredField' });
    }
    if (key === 'url' && errors[key] === 'invalid') {
      return formatMessage({ id: 'createSystemDialog.wrongUrl' });
    }
    return null;
  };

  return (
    <Dialog open onClose={onClose} className="dialog form system-dialog" fullWidth maxWidth="md">
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <FormSection title={formatMessage({ id: 'createSystemDialog.basicDatas' })}>
          {Object.keys(systemFormFields).map(key => (
            <div key={key} className="field">
              <div className="label">
                <FormattedMessage id={`createSystemDialog.${key}`} />
              </div>
              <div className="widget">
                <TextField
                  required
                  name={key}
                  className={classnames('text-field', `field-${key}`)}
                  variant="outlined"
                  value={systemForm[key] || ''}
                  error={!!errors[key]}
                  onChange={handleInputChange}
                  inputProps={{ maxLength: systemFormFields[key]?.max }}
                />
                {errors[key] && <span className="error">{translateText(key)}</span>}
              </div>
            </div>
          ))}
          {isEdit && (
            <div key="apiKey" className="field">
              <div className="label">
                <FormattedMessage id="createSystemDialog.apiKey" />
              </div>
              <div className="widget">
                <TextField
                  name="apiKey"
                  className={classnames('text-field', 'field-apiKey')}
                  variant="outlined"
                  value={initialSystemForm.apiKey}
                  inputProps={{ maxLength: 36 }}
                  disabled
                />
              </div>
            </div>
          )}
        </FormSection>

        <FormSection title={formatMessage({ id: 'createSystemDialog.invoiceDatas' })}>
          {Object.keys(invoiceFormFields).map(key =>
            key === 'password' ? (
              <div key={key} className="field">
                <div className="field">
                  <FormattedMessage id={`createSystemDialog.invoice.${key}`} />
                </div>
                <div className="widget">
                  <OutlinedInput
                    required
                    name={key}
                    className={classnames('text-field', `field-invoice-${key}`)}
                    type={showPassword ? 'text' : 'password'}
                    value={invoiceForm[key]}
                    error={!!errors[key]}
                    onChange={handleInvoiceInputChange}
                    inputProps={{ maxLength: invoiceFormFields[key]?.max }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(prev => !prev)}
                          className="show-password-icon"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errors[key] && <span className="error">{translateText(key)}</span>}
                </div>
              </div>
            ) : (
              <div key={key} className="field">
                <div className="field">
                  <FormattedMessage id={`createSystemDialog.invoice.${key}`} />
                </div>
                <div className="widget">
                  <TextField
                    required
                    name={key}
                    className={classnames('text-field', `field-invoice-${key}`)}
                    variant="outlined"
                    value={invoiceForm[key]}
                    error={!!errors[key]}
                    onChange={handleInvoiceInputChange}
                    inputProps={{ maxLength: invoiceFormFields[key]?.max }}
                  />
                  {errors[key] && <span className="error">{translateText(key)}</span>}
                </div>
              </div>
            )
          )}
        </FormSection>
      </DialogContent>

      {saveError && (
        <div className="save-error">
          <span>
            <FormattedMessage
              id={
                isEdit
                  ? 'updatesystemDialog.failedToUpdateSystem'
                  : 'createSystemDialog.failedToCreateSystem'
              }
            />
          </span>
          {saveError === SAVE_ERROR.CONFLICT && (
            <span>
              <FormattedMessage id="createSystemDialog.idAlreadyExists" />
            </span>
          )}
        </div>
      )}

      <DialogActions>
        <Button variant="contained" className="primary-button" onClick={onSubmit}>
          <FormattedMessage id="common.buttons.save" />
        </Button>
        <Button variant="contained" className="secondary-button" onClick={onClose}>
          <FormattedMessage id="common.buttons.cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SystemDialog;
