import { useContext, useState, useEffect } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';

import { setToken } from '../rest/rest';
import Loading from '../components/Loading';
import ErrorPage from './ErrorPage';

import { AppContext } from '../context/AppContextProvider';

const LoginGuard = ({ children }) => {
  const auth = useAuth();
  const { user, setUser } = useContext(AppContext);

  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }

    if (auth.isAuthenticated) {
      setToken(auth.user.access_token);
      setInitialized(true);

      const _user = {
        firstName: auth.user.profile.given_name,
        lastName: auth.user.profile.family_name
      };
      if (user?.firstName !== _user.firstName || user?.lastName !== _user.lastName) {
        setUser(_user);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, hasTriedSignin]);

  if (auth.activeNavigator === 'signoutRedirect') {
    return <Loading messageId="app.logoutInProgress" />;
  }

  if (!auth.isAuthenticated || !initialized) {
    return <Loading messageId="app.initializing" />;
  }

  if (auth.isLoading) {
    return <Loading />;
  }

  if (auth.error) {
    return <ErrorPage errorMessage={auth.error.message} />;
  }

  return children;
};

export default LoginGuard;
