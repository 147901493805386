import { CssBaseline } from '@mui/material';

import LoginGuard from './containers/LoginGuard';
import AppRouter from './containers/App/AppRouter';

import AppContextProvider from './context/AppContextProvider';

const App = () => (
  <>
    <CssBaseline />
    <AppContextProvider>
      <LoginGuard>
        <AppRouter />
      </LoginGuard>
    </AppContextProvider>
  </>
);

export default App;
