import { useState, createContext, useMemo, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert, Container } from '@mui/material';
import log from 'loglevel';

import Page from '../containers/Layout/Page';

import { getSystems } from '../rest/resource/systems';

const logger = log.getLogger('SystemContextProvider');

const SystemContext = createContext();

const SystemContextProvider = props => {
  const [allSystems, setAllSystems] = useState();
  const [loadingError, setLoadingError] = useState();

  const fetchSystems = async () => {
    try {
      const systems = await getSystems();
      setAllSystems(systems);
    } catch (err) {
      logger.error('Failed to load systems!', err);
      setLoadingError(err);
    }
  };

  useEffect(() => {
    fetchSystems();
  }, []);

  const contextValue = useMemo(
    () => ({ allSystem: allSystems, refresh: fetchSystems }),
    [allSystems]
  );

  if (loadingError) {
    return (
      <Page>
        <Container>
          <Alert severity="error">
            <FormattedMessage id="systemList.error" />
          </Alert>
        </Container>
      </Page>
    );
  }

  if (allSystems) {
    return <SystemContext.Provider value={contextValue}>{props.children}</SystemContext.Provider>;
  }

  return <Page />;
};

export { SystemContext };
export default SystemContextProvider;
