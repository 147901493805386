import { useState, useEffect, useCallback, useMemo, createContext } from 'react';
import { IntlProvider } from 'react-intl';
import { AuthProvider } from 'react-oidc-context';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import huLocale from 'date-fns/locale/hu';
import enLocale from 'date-fns/locale/en-US';
import log from 'loglevel';

import { getConfig, getTranslations } from '../rest/resource/assets';
import Loading from '../components/Loading';
import ErrorPage from '../containers/ErrorPage';

const states = {
  LOADING: 'loading',
  INITIALIZED: 'initialized',
  ERROR: 'error'
};

const adapterLocales = {
  hu: huLocale,
  en: enLocale
};

const logger = log.getLogger('AppContextProvider');

const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [state, setState] = useState(states.LOADING);
  const [config, setConfig] = useState();
  const [translations, setTranslations] = useState();

  const [user, setUser] = useState();
  const [organization, setOrganization] = useState();
  const [locale, setLocale] = useState();
  const [availableLocales, setAvailableLocales] = useState();

  useEffect(() => {
    (async () => {
      try {
        const configResponse = await getConfig();
        const translationResponse = await getTranslations();

        setConfig({ keycloakConfig: configResponse.keycloakConfig });
        setLocale(configResponse.locales[0]);
        setAvailableLocales(configResponse.locales);
        setTranslations(translationResponse);
        setState(states.INITIALIZED);
      } catch (err) {
        logger.error('Failed to load static assets.', err);
        setState(states.ERROR);
      }
    })();
  }, []);

  const changeLocale = useCallback(
    newLocale => {
      if (availableLocales.includes(newLocale)) {
        setLocale(newLocale);
      } else {
        logger.error(
          `Failed to change locale to: ${newLocale}! Available locales: ${availableLocales}.`
        );
      }
    },
    [availableLocales]
  );

  const contextValue = useMemo(
    () => ({
      locale,
      availableLocales,
      user,
      organization,
      changeLocale,
      setLocale,
      setAvailableLocales,
      setUser,
      setOrganization
    }),
    [locale, availableLocales, user, organization, changeLocale]
  );

  const renderContent = () => {
    const oidcConfig = {
      ...config.keycloakConfig,
      onSigninCallback: () => {
        window.history.replaceState({}, document.title, window.location.pathname);
      }
    };

    return (
      <IntlProvider locale={locale} messages={translations[locale]}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocales[locale]}>
          <AuthProvider {...oidcConfig}>{children}</AuthProvider>
        </LocalizationProvider>
      </IntlProvider>
    );
  };

  return (
    <AppContext.Provider value={contextValue}>
      {state === states.LOADING && <Loading />}
      {/* FIXME: If we fail to load translations... how are we goind to provide a human readable error page? */}
      {state === states.ERROR && <ErrorPage errorId="app.failedToLoad" />}
      {state === states.INITIALIZED && renderContent()}
    </AppContext.Provider>
  );
};

export { AppContext };
export default AppContextProvider;
