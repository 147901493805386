import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Divider } from '@mui/material';

import AdministrationBlock from '../../components/MenuBar/AdministrationBlock';
import UserBlock from '../../components/MenuBar/UserBlock';

import appLogo from '../../assets/img/app_logo.svg';

const Header = () => (
  <header>
    <a href="/" className="title-wrapper">
      <img className="app-logo" src={appLogo} alt="Qulto Payment" />
      <span className="app-name">
        <FormattedMessage id="app.name" />
      </span>
    </a>
    <div className="right-side-block">
      <AdministrationBlock />
      <Divider orientation="vertical" flexItem />
      <UserBlock />
    </div>
  </header>
);

export default Header;
