import { useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import { IconButton } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import log from 'loglevel';

import Table from '../Table';
import Loading from '../Loading';
import ImportResultModal from './ImportResultModal';
import ConfirmationDialog from '../Modals/ConfirmationDialog';

import { LOAD_STATUS } from '../constants';
import { delay } from '../delay';

import { getTransactionsByImport } from '../../rest/resource/import';

const logger = log.getLogger('ImportList');

const FIELDS = ['filename', 'username', 'importDate', 'numberOfRecords'];
const PAGE_SIZE = 10;
const DEFAULT_SORT = { field: 'importDate', direction: -1 };

const ImportList = ({ loadStatus, imports }) => {
  const { formatMessage } = useIntl();

  const [importOpen, setImportOpen] = useState({ open: false });
  const [importLoadingStatus, setImportLoadingStatus] = useState(LOAD_STATUS.SUCCESS);

  const showImport = async importId => {
    setImportLoadingStatus(LOAD_STATUS.LOADING);

    try {
      const transactions = await getTransactionsByImport(importId);

      await delay();

      setImportLoadingStatus(LOAD_STATUS.SUCCESS);
      setImportOpen({ open: true, transactions });
    } catch (err) {
      setImportOpen({ open: false });
      setImportLoadingStatus(LOAD_STATUS.ERROR);
      logger.error('Failed to load imported transactions.', err);
    }
  };

  const handleImportModalClose = () => {
    setImportOpen({ open: false });
  };

  const handleImportOpenErrorModalClose = () => {
    setImportLoadingStatus(LOAD_STATUS.SUCCESS);
  };

  const renderRow = importData => (
    <div className="table-content" key={importData.id}>
      {FIELDS.map(field => (
        <div key={field} className="field-data">
          {field === 'importDate' ? (
            <span>
              <FormattedDate value={importData[field]} />{' '}
              <FormattedTime value={importData[field]} />
            </span>
          ) : (
            <span title={importData[field]}>{importData[field]}</span>
          )}
        </div>
      ))}
      <div className="actions">
        <IconButton className="action-button" onClick={() => showImport(importData.id)}>
          <Visibility />
        </IconButton>
      </div>
    </div>
  );

  const renderImports = () => {
    switch (loadStatus) {
      case LOAD_STATUS.LOADING:
        return <Loading />;
      case LOAD_STATUS.SUCCESS:
        return imports.length ? (
          <Table
            fields={FIELDS}
            rows={imports}
            renderRow={renderRow}
            translationKey="accounting.import.list"
            className="import-list"
            pageSize={PAGE_SIZE}
            defaultSort={DEFAULT_SORT}
          />
        ) : (
          <div className="import-list-message">
            <FormattedMessage id="accounting.import.list.empty" />
          </div>
        );
      default:
        return (
          <div className="import-list-message">
            <FormattedMessage id="accounting.import.list.error" />
          </div>
        );
    }
  };

  const renderOpenImport = () => {
    switch (importLoadingStatus) {
      case LOAD_STATUS.LOADING:
        return (
          <div className="open-import-loading-container">
            <Loading />
          </div>
        );
      case LOAD_STATUS.SUCCESS:
        return importOpen.open ? (
          <ImportResultModal onClose={handleImportModalClose} results={importOpen.transactions} />
        ) : null;
      case LOAD_STATUS.ERROR:
        return (
          <ConfirmationDialog
            open
            onClose={handleImportOpenErrorModalClose}
            title={formatMessage({ id: 'accounting.import.show.error.title' })}
            text={formatMessage({ id: 'accounting.import.show.error.message' })}
            actions={[
              {
                name: 'confirm',
                onClick: handleImportOpenErrorModalClose,
                className: 'primary-button'
              }
            ]}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="import-list-container">
      <div className="import-list-title">
        <FormattedMessage id="accounting.import.list.title" />
      </div>
      {renderImports()}
      {renderOpenImport()}
    </div>
  );
};

ImportList.defaultProps = {
  imports: []
};

export default ImportList;
