import { Breadcrumbs, Link, Typography } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';

const CustomBreadcrumbs = ({ steps }) => {
  const breadcrumbs = steps.map((step, index) => {
    if (index === steps.length - 1) {
      return <Typography key={index}>{step.label}</Typography>;
    }
    return (
      <Link underline="hover" key={index} href={step.ref}>
        {step.label}
      </Link>
    );
  });

  return (
    <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
      {breadcrumbs}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
