import { useContext } from 'react';

import UserMenu from './UserMenu';

import { AppContext } from '../../context/AppContextProvider';
import LanguageSelector from './LanguageSelector';

const UserBlock = () => {
  const { user, organization } = useContext(AppContext);

  return (
    <div className="user-block">
      <div className="username">
        <div className="name">
          {user.lastName} {user.firstName}
        </div>
        {organization && <div className="organization">{organization.name}</div>}
      </div>
      <UserMenu />
      <LanguageSelector />
    </div>
  );
};

export default UserBlock;
