import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom';
import SystemList from '../SystemList/SystemList';

import RootLayout from './RootLayout';
import AccountingImport from '../AccountingImport/AccountingImport';
import AccountingTransactions from '../AccountingTransactions/AccountingTransactions';
import AccountingClosure from '../AccountingClosure/AccountingClosure';
import AccountingSystemClosure from '../AccountingClosure/AccountingSystemClosure';
import AccountingClosureDetails from '../AccountingClosure/AccountingClosureDetails';

const AppRouter = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<RootLayout />}>
        <Route path="systems" element={<SystemList />} />
        <Route path="accounting/import" element={<AccountingImport />} />
        <Route path="accounting/transactions" element={<AccountingTransactions />} />
        <Route path="accounting/closure" element={<AccountingClosure />} />
        <Route path="accounting/closure/:id" element={<AccountingClosureDetails />} />
        <Route path="accounting/closure/:id/:systemId" element={<AccountingSystemClosure />} />
        <Route path="*" element={<Navigate to="/systems" replace />} />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};

export default AppRouter;
