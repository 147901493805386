import { api } from '../rest';

const uploadFile = async file => {
  const formData = new FormData();
  formData.append('file', file);

  return (await api.post('/accounting/import', formData)).data;
};

const getImports = async () => (await api.get('/accounting/import')).data;

const getTransactionsByImport = async importId =>
  (await api.get(`/accounting/import/${importId}`)).data;

export { uploadFile, getImports, getTransactionsByImport };
