import { api } from '../rest';

const getTransactions = async searchParams =>
  (
    await api.get('/accounting/transactions', {
      params: { ...searchParams },
      paramsSerializer: {
        indexes: null
      }
    })
  ).data;

export { getTransactions };
