import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { parseISO } from 'date-fns';
import { useState } from 'react';

const DatePicker = ({ value, onChange, placeholder }) => {
  const [open, setOpen] = useState(false);

  return (
    <MuiDatePicker
      value={value ? parseISO(value) : null}
      onChange={onChange}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      slotProps={{
        textField: {
          placeholder,
          readOnly: true,
          onClick: () => setOpen(true)
        }
      }}
    />
  );
};

export default DatePicker;
