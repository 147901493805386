import AccountingMenu from './AccountingMenu';
import SystemMenu from './SystemMenu';

const AdministrationBlock = () => (
  <div className="administration-block">
    <SystemMenu />
    <AccountingMenu />
  </div>
);

export default AdministrationBlock;
