import React from 'react';
import { FormattedMessage } from 'react-intl';

const SystemTableRowDetails = ({ system }) => (
  <>
    <div>{/* Expand button placeholder */}</div>
    <div className="system-details">
      <div className="invoice-field">
        <div className="label">
          <FormattedMessage id="systemList.bank" />
        </div>
        <div className="value">{system.invoice.bankName}</div>
      </div>
      <div className="invoice-field">
        <div className="label">
          <FormattedMessage id="systemList.bankAccountNumber" />
        </div>
        <div className="value">{system.invoice.bankAccountNumber}</div>
      </div>
      <div className="invoice-field">
        <div className="label">
          <FormattedMessage id="systemList.invoiceDescription" />
        </div>
        <div className="value description">{system.invoice.description}</div>
      </div>
    </div>
  </>
);

export default SystemTableRowDetails;
