import axios from 'axios';

const newInstance = url => axios.create({ baseURL: url });

// public resources
const pub = newInstance();

// REST API endpoints
const api = newInstance('/api');

const setToken = token => {
  api.defaults.headers.Authorization = `Bearer ${token}`;
  api.defaults.headers['x-app'] = 'payment-backend';
};

export { pub, api, setToken };
