import { Button, IconButton } from '@mui/material';
import { FirstPage, LastPage, NavigateBefore, NavigateNext } from '@mui/icons-material';
import classnames from 'classnames';

const pagesToShow = (pages, currentPage, numberOfPagesToShow) => {
  if (pages <= numberOfPagesToShow) {
    return Array.from({ length: pages }).map((_, index) => index + 1);
  }

  const offset = parseInt(numberOfPagesToShow / 2, 10);
  const centerPage = Math.min(Math.max(offset + 1, currentPage), pages - offset);

  return Array.from({ length: numberOfPagesToShow }).map((_, index) => index + centerPage - offset);
};

const Paginator = ({ pages, currentPage, numberOfPagesToShow, onChange }) =>
  pages > 1 ? (
    <div className="paginator">
      <IconButton className="first" onClick={() => onChange(1)} disabled={currentPage < 2}>
        <FirstPage />
      </IconButton>
      <IconButton
        className="prev"
        onClick={() => onChange(currentPage - 1)}
        disabled={currentPage < 2}
      >
        <NavigateBefore />
      </IconButton>
      {pagesToShow(pages, currentPage, numberOfPagesToShow).map(page => (
        <Button
          key={page}
          variant="text"
          className={classnames('page-option', { 'current-page': page === currentPage })}
          onClick={() => onChange(page)}
        >
          <span>{page}</span>
        </Button>
      ))}
      <IconButton
        className="next"
        onClick={() => onChange(currentPage + 1)}
        disabled={currentPage > pages - 1}
      >
        <NavigateNext />
      </IconButton>
      <IconButton
        className="last"
        onClick={() => onChange(pages)}
        disabled={currentPage > pages - 1}
      >
        <LastPage />
      </IconButton>
    </div>
  ) : null;

Paginator.defaultProps = {
  numberOfPagesToShow: 5
};

export default Paginator;
