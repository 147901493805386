import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { Done, HourglassEmpty, Visibility } from '@mui/icons-material';

import Table from '../Table';
import { DEFAULT_CURRENCY } from '../constants';

const FIELDS = [
  'systemName',
  'numberOfTransactions',
  'total',
  'totalCommission',
  'commissionReducedTotal',
  'status'
];
const PAGE_SIZE = 10;
const DEFAULT_SORT = { field: 'systemName', direction: 1 };

const GroupedClosureList = ({ closureId, closureBySystem }) => {
  const navigate = useNavigate();

  const handleGoToSystemClosure = systemId => {
    navigate(`/accounting/closure/${closureId}/${systemId}`);
  };

  const renderField = (field, closure) => {
    switch (field) {
      case 'status': {
        if (closure[field] === 'OPEN') {
          return (
            <div key={field} className="field-data closure-open">
              <HourglassEmpty />{' '}
              <FormattedMessage id={`accounting.closure.grouped.list.statuses.${closure[field]}`} />
            </div>
          );
        }
        return (
          <div key={field} className="field-data closure-closed">
            <Done />{' '}
            <FormattedMessage id={`accounting.closure.grouped.list.statuses.${closure[field]}`} />
          </div>
        );
      }

      case 'total':
      case 'totalCommission':
      case 'commissionReducedTotal':
        return (
          <div key={field} className="field-data amount">
            <FormattedNumber
              value={closure[field]}
              // eslint-disable-next-line react/style-prop-object
              style="currency"
              currency={closure.currency || DEFAULT_CURRENCY}
              minimumFractionDigits={0}
              maximumFractionDigits={0}
            />
          </div>
        );
      default:
        return (
          <div key={field} className="field-data">
            <span title={closure[field]}>{closure[field]}</span>
          </div>
        );
    }
  };

  const renderRow = closure => (
    <div className="table-content closure-item" key={closure.systemId}>
      {FIELDS.map(field => renderField(field, closure))}
      <div className="actions">
        <IconButton onClick={() => handleGoToSystemClosure(closure.systemId)}>
          <Visibility />
        </IconButton>
      </div>
    </div>
  );

  return (
    <div className="grouped-closure-list">
      <div className="closure-list-title">
        <FormattedMessage id="accounting.closure.grouped.list.title" />
      </div>
      <Table
        fields={FIELDS}
        rows={closureBySystem}
        renderRow={renderRow}
        translationKey="accounting.closure.grouped.list"
        className="grouped-closure-list-table"
        pageSize={PAGE_SIZE}
        defaultSort={DEFAULT_SORT}
      />
    </div>
  );
};

export default GroupedClosureList;
