import { Warning } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

const ErrorPage = ({ errorId, errorMessage }) => (
  <div className="error-page">
    <Warning />
    <div className="message">{errorId ? <FormattedMessage id={errorId} /> : errorMessage}</div>
  </div>
);

export default ErrorPage;
