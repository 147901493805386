import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';

const HeaderMenu = ({ renderTitle, menuItems, hideExpandIcon }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = ({ currentTarget }) => setAnchorEl(currentTarget);

  const handleClose = () => setAnchorEl(null);

  return (
    <div>
      <div className="menu-title" role="button" tabIndex={0} onClick={handleClick}>
        {renderTitle()}
        {menuItems.length && !hideExpandIcon && (
          <IconButton className="expand-button" disableRipple>
            {anchorEl ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </div>
      <Menu
        className="header-menu-list"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={handleClose}
      >
        {menuItems.map((item, index) => (
          <MenuItem key={index} onClick={handleClose} disabled={item.disabled}>
            {item.element}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default HeaderMenu;
