import qultoLogo from '../../assets/img/qulto-logo.svg';

const Footer = () => (
  <footer>
    <span className="copyright">Copyright 2021-{new Date().getFullYear()} Monguz Kft.</span>
    <a href="https://qulto.eu">
      <img className="qulto-logo" src={qultoLogo} alt="Qulto" />
    </a>
  </footer>
);

export default Footer;
