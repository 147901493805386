import { createElement } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './assets/style/index.scss';

const app = document.createElement('div');
app.id = 'app';
document.body.appendChild(app);

const root = createRoot(app);
root.render(createElement(App));
