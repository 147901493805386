import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const SystemMenu = () => (
  <div className="system-menu">
    <Link to="/systems" className="menu-title">
      <FormattedMessage id="menu.system.title" />
    </Link>
  </div>
);

export default SystemMenu;
