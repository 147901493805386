import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDropzone } from 'react-dropzone';
import { LinearProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import ConfirmationDialog from './Modals/ConfirmationDialog';

import { uploadFile } from '../rest/resource/import';

const FileUpload = ({ onUploadSuccess, multiple, acceptedFormats }) => {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [uploadError, setUploadError] = useState(false);

  const onDrop = useCallback(
    async acceptedFiles => {
      setLoading(true);

      try {
        const response = await uploadFile(acceptedFiles[0]);
        onUploadSuccess(response);
      } catch (error) {
        setUploadError(true);
      } finally {
        setLoading(false);
      }
    },
    [onUploadSuccess]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFormats,
    multiple,
    disabled: loading || uploadError
  });

  return (
    <div className="file-upload-dropzone" {...getRootProps()}>
      <input {...getInputProps()} />
      <CloudUploadIcon className="file-upload-icon" />
      <p>
        <FormattedMessage id="accounting.import.upload" />
      </p>
      <div className="file-upload-loading">{loading && <LinearProgress />}</div>
      {uploadError && (
        <ConfirmationDialog
          open={uploadError}
          title={formatMessage({ id: 'accounting.import.error.title' })}
          text={formatMessage({ id: 'accounting.import.error.message' })}
          actions={[
            { name: 'confirm', onClick: () => setUploadError(false), className: 'primary-button' }
          ]}
          onClose={() => setUploadError(false)}
        />
      )}
    </div>
  );
};

FileUpload.defaultProps = {
  multiple: false
};

export default FileUpload;
