import { useEffect, useState } from 'react';
import log from 'loglevel';

import Page from '../Layout/Page';
import FileUpload from '../../components/FileUpload';
import ImportResultModal from '../../components/AccountingImport/ImportResultModal';
import ImportList from '../../components/AccountingImport/ImportList';

import { LOAD_STATUS } from '../../components/constants';

import { getImports } from '../../rest/resource/import';

const logger = log.getLogger('AccountingImport');

const AccountingImport = () => {
  const [imports, setImports] = useState([]);
  const [loadStatus, setLoadStatus] = useState(LOAD_STATUS.LOADING);
  const [resultModalOpen, setResultModalOpen] = useState(false);
  const [importResult, setImportResult] = useState();

  const loadImports = async () => {
    try {
      const importsResponse = await getImports();
      setImports(importsResponse);
      setLoadStatus(LOAD_STATUS.SUCCESS);
    } catch (err) {
      logger.error('Failed to get imports.', err);
      setLoadStatus(LOAD_STATUS.ERROR);
    }
  };

  useEffect(() => {
    loadImports();
  }, []);

  const handleFileImportResult = result => {
    setResultModalOpen(true);
    setImportResult(result);
  };

  const handleImportResultModalClose = async () => {
    setResultModalOpen(false);
    loadImports();
  };

  return (
    <Page className="import-page" titleId="accounting.import.title" back>
      <FileUpload
        onUploadSuccess={handleFileImportResult}
        acceptedFormats={{ 'text/csv': ['.csv'] }}
      />

      <ImportList loadStatus={loadStatus} imports={imports} />

      {resultModalOpen && importResult && (
        <ImportResultModal onClose={handleImportResultModalClose} results={importResult} />
      )}
    </Page>
  );
};

export default AccountingImport;
