import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Close, Done } from '@mui/icons-material';

import Table from '../Table';

const FIELDS = [
  'id',
  'paymentType',
  'transactionDate',
  'transactionAmount',
  'currency',
  'customer',
  'importStatus'
];
const PAGE_SIZE = 10;
const DEFAULT_SORT = { field: 'id', direction: 1 };

const ImportStatusField = ({ status }) =>
  status.startsWith('SUCCESSFUL') ? (
    <span className="import-status-success">
      <div>
        <Done /> <FormattedMessage id="accounting.import.result.successful" />
      </div>
      {status !== 'SUCCESSFUL' && (
        <div className="status-reason">
          <FormattedMessage id={`accounting.import.result.${status}`} />
        </div>
      )}
    </span>
  ) : (
    <span className="import-status-failed">
      <div>
        <Close /> <FormattedMessage id="accounting.import.result.failed" />
      </div>
      <div className="status-reason">
        <FormattedMessage id={`accounting.import.result.${status}`} />
      </div>
    </span>
  );

const renderField = (field, value) => {
  switch (field) {
    case 'importStatus':
      return <ImportStatusField status={value} />;
    case 'transactionDate':
      return (
        <span>
          <FormattedDate value={value} /> <FormattedTime value={value} />
        </span>
      );
    default:
      return <span title={value}>{value}</span>;
  }
};

const ImportResultModal = ({ onClose, results }) => {
  const renderRow = importResult => (
    <div className="table-content import-result-item" key={importResult.id}>
      {FIELDS.map(field => (
        <div key={field} className="field-data">
          {renderField(field, importResult[field])}
        </div>
      ))}
    </div>
  );

  return (
    <Dialog open onClose={onClose} className="dialog import-result-modal" fullWidth maxWidth="md">
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <FormattedMessage id="accounting.import.result.title" />
          </Box>
          <Box>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Table
          fields={FIELDS}
          rows={results}
          renderRow={renderRow}
          translationKey="accounting.import.result"
          className="import-result-list"
          pageSize={PAGE_SIZE}
          defaultSort={DEFAULT_SORT}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ImportResultModal;
