import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import ActionBar from './ActionBar';
import SearchBar from './SearchBar';

const Page = ({ className, titleId, title, search, actions, breadcrumbs, back, children }) => (
  <>
    <ActionBar
      title={titleId ? <FormattedMessage id={titleId} /> : title}
      actions={actions}
      breadcrumbs={breadcrumbs}
      back={back}
    />
    {search && <SearchBar {...search} />}
    <div className={classnames('page', className)}>{children}</div>
  </>
);

export default Page;
