import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Sort } from '@mui/icons-material';
import classnames from 'classnames';

import Paginator from './Paginator';

const Table = ({ fields, rows, renderRow, translationKey, className, defaultSort, pageSize }) => {
  const [sort, setSort] = useState(defaultSort || { field: fields[0], direction: -1 });
  const [page, setPage] = useState(1);

  const sortBy = field => {
    setSort(prev => {
      if (prev.field === field) {
        return { ...prev, direction: -prev.direction };
      }

      return { field, direction: 1 };
    });
  };

  const compare = (a, b) => {
    if (typeof a[sort.field] === 'number') {
      return sort.direction * (a[sort.field] - b[sort.field]);
    }
    return sort.direction * a[sort.field].localeCompare(b[sort.field]);
  };

  const pageStartIndex = (page - 1) * pageSize;
  const pageEndIndex = pageStartIndex + pageSize;

  return (
    <div className="table">
      <div className={classnames('table-body', className)}>
        <div className="table-heads">
          {fields.map(field => (
            <div key={field} className={`table-head-${field}`}>
              <div role="button" tabIndex={0} className="table-head" onClick={() => sortBy(field)}>
                <FormattedMessage id={`${translationKey}.${field}`} />
                {sort.field === field ? (
                  <Sort className={classnames({ flipped: sort.direction === 1 })} />
                ) : (
                  <div className="sort-placeholder"></div>
                )}
              </div>
            </div>
          ))}
          <div className="actions-column-placeholder"></div>
        </div>

        {rows.sort(compare).slice(pageStartIndex, pageEndIndex).map(renderRow)}
      </div>

      <Paginator pages={Math.ceil(rows.length / pageSize)} currentPage={page} onChange={setPage} />
    </div>
  );
};

export default Table;
