import { CircularProgress } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const Loading = ({ message, messageId }) => (
  <div className="loading">
    <CircularProgress />
    {messageId && <FormattedMessage id={messageId} />}
    {message && <span>message</span>}
  </div>
);

export default Loading;
