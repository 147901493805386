import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';

import ActionButton from '../../components/ActionButton';
import CustomBreadcrumbs from './Breadcrumbs';

const ActionBar = ({ title, actions, breadcrumbs, back }) => {
  const navigate = useNavigate();
  return (
    <div className="action-bar">
      {back && (
        <div className="back">
          <ArrowBack onClick={() => navigate(-1)} />
        </div>
      )}
      {breadcrumbs && (
        <div className="breadcrumbs">
          <CustomBreadcrumbs steps={breadcrumbs} />
        </div>
      )}

      <span className="title">{title}</span>
      <div className="actions">
        {actions?.map((action, index) => (
          <ActionButton key={index} {...action} />
        ))}
      </div>
    </div>
  );
};

ActionBar.defaultProps = {
  title: <>&nbsp;</>
};

export default ActionBar;
