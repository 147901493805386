import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, IconButton, TextField, Tooltip } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';

import DatePicker from '../DatePicker';
import FormSection from '../FormSection';
import ConfirmationDialog from '../Modals/ConfirmationDialog';
import { formatDate } from '../DateFormatter';

const ClosureCreateForm = ({ onConfirm }) => {
  const { formatMessage } = useIntl();

  const [closureDate, setClosureDate] = useState(formatDate(new Date()));
  const [note, setNote] = useState();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const handleClosureDateChange = value => {
    setClosureDate(formatDate(value));
  };

  const handleNoteChange = ({ target: { value } }) => {
    setNote(value);
  };

  const handleFormConfirmation = () => {
    setOpenConfirmationModal(true);
  };

  const handleConfirmationModalClose = () => {
    setOpenConfirmationModal(false);
  };

  const handleClosureStart = () => {
    onConfirm({ closureDate, note });
    setOpenConfirmationModal(false);
  };

  return (
    <>
      <FormSection
        title={formatMessage({ id: 'accounting.closure.create.title' })}
        className="closure-form"
      >
        <div className="closure-form-item closure-form-date">
          <span className="label">
            <FormattedMessage id="accounting.closure.create.closureDate.title" />
            <Tooltip title={formatMessage({ id: 'accounting.closure.create.closureDate.help' })}>
              <IconButton>
                <HelpOutline />
              </IconButton>
            </Tooltip>
          </span>
          <DatePicker value={closureDate} onChange={handleClosureDateChange} />
        </div>

        <div className="closure-form-item">
          <span className="label">
            <FormattedMessage id="accounting.closure.create.note" />
          </span>
          <TextField multiline rows={4} value={note} onChange={handleNoteChange} />
        </div>

        <div className="form-actions-container">
          <Button variant="contained" className="primary-button" onClick={handleFormConfirmation}>
            <FormattedMessage id="accounting.closure.create.start" />
          </Button>
        </div>
      </FormSection>
      {openConfirmationModal && (
        <ConfirmationDialog
          open={openConfirmationModal}
          className="closure-start-confirmation-modal"
          onClose={handleConfirmationModalClose}
          title={formatMessage({ id: 'accounting.closure.create.modal.title' })}
          text={formatMessage(
            { id: 'accounting.closure.create.modal.message' },
            {
              value: closureDate,
              // eslint-disable-next-line react/no-unstable-nested-components
              b: str => <b>{str}</b>
            }
          )}
          actions={[
            {
              name: 'start',
              onClick: handleClosureStart,
              className: 'primary-button'
            },
            {
              name: 'cancel',
              onClick: handleConfirmationModalClose,
              className: 'secondary-button'
            }
          ]}
        />
      )}
    </>
  );
};

export default ClosureCreateForm;
