import { pub } from '../rest';

const getConfig = async () => {
  const { data } = await pub.get('/application.json');
  return data;
};

const getTranslations = async () => {
  const { data: hu } = await pub.get('/locale/hu.json');
  const { data: en } = await pub.get('/locale/en.json');
  return { hu, en };
};

export { getConfig, getTranslations };
