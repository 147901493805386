import { MenuItem, TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const types = ['id', 'name', 'description', 'url', 'apiKey', 'all'];

const SystemFilter = ({ values, onChange }) => (
  <>
    <div className="filter">
      <span className="label">
        <FormattedMessage id="systemList.search.type" />
      </span>
      <TextField
        select
        className="small-input system-list-filter-type"
        variant="outlined"
        name="type"
        value={values.type}
        onChange={onChange}
      >
        {types.map(type => (
          <MenuItem className="menu-item" key={type} value={type}>
            <FormattedMessage id={`systemList.${type}`} />
          </MenuItem>
        ))}
      </TextField>
    </div>
    <div className="filter">
      <span className="label">
        <FormattedMessage id="systemList.search.text" />
      </span>
      <TextField
        className="small-input system-list-filter-text"
        variant="outlined"
        name="text"
        value={values.text}
        onChange={onChange}
      />
    </div>
  </>
);

export default SystemFilter;
