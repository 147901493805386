import { useState, useEffect, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Add, Sort } from '@mui/icons-material';
import { Snackbar } from '@mui/material';
import classnames from 'classnames';

import Page from '../Layout/Page';
import SystemFilter from '../../components/System/SystemFilter';
import SystemTableRow from '../../components/System/SystemTableRow';
import SystemDialog from '../../components/Modals/SystemDialog';
import ConfirmationDialog from '../../components/Modals/ConfirmationDialog';

import { SystemContext } from '../../context/SystemContextProvider';
import { deleteSystem, createSystem, updateSystem } from '../../rest/resource/systems';
import Paginator from '../../components/Paginator';

const fields = ['id', 'name', 'description', 'url', 'apiKey'];
const defaultSort = { field: 'id', direction: -1 };
const defaultFilter = { type: 'all', text: '' };

const pageSize = 10;

const SystemList = () => {
  const { allSystem, refresh } = useContext(SystemContext);
  const { formatMessage } = useIntl();

  const [confirmationDialog, setConfirmationDialog] = useState({ visible: false });
  const [snackbar, setSnackbar] = useState({ visible: false });
  const [systems, setSystems] = useState(allSystem);
  const [systemForm, setSystemForm] = useState();
  const [filters, setFilters] = useState(defaultFilter);
  const [sort, setSort] = useState(defaultSort);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setSystems(allSystem);
  }, [allSystem]);

  const deleteSystemById = async systemId => {
    try {
      await deleteSystem(systemId);
      setSnackbar({ visible: true, text: formatMessage({ id: 'systemList.successDelete' }) });
    } catch (err) {
      setSnackbar({ visible: true, text: formatMessage({ id: 'systemList.deletedFailed' }) });
    } finally {
      setConfirmationDialog({ visible: false });
      refresh();
    }
  };

  const openConfirmationModal = systemId =>
    setConfirmationDialog({ visible: true, selectedId: systemId });

  const createNewSystem = () => {
    setSystemForm({});
  };

  const editSystem = system => {
    setSystemForm(system);
  };

  const closeModal = () => {
    setSystemForm(null);
  };

  const save = async systemData => {
    try {
      if (systemForm.id) {
        await updateSystem(systemForm.id, systemData);
      } else {
        await createSystem(systemData);
      }
    } finally {
      refresh();
    }
  };

  const sortBy = field => {
    setSort(prev => {
      if (prev.field === field) {
        return { ...prev, direction: -prev.direction };
      }

      return { field, direction: 1 };
    });
  };

  const handleFilterChanges = ({ target: { name, value } }) => {
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleSearch = (_, currentFilters = filters) => {
    const convertedText = currentFilters.text.toUpperCase();

    if (convertedText) {
      if (currentFilters.type !== 'all') {
        const newFilteredSystems = allSystem.filter(system => {
          if (system === 'invoice') {
            return false;
          }
          const convertedTypeValue = system[currentFilters.type].toUpperCase();
          return convertedTypeValue.includes(convertedText);
        });

        setSystems(newFilteredSystems);
      } else {
        const newFilteredSystems = allSystem.filter(system =>
          Object.values(system).some(item => {
            if (typeof item === 'object') {
              return false;
            }
            return item.toUpperCase().includes(convertedText);
          })
        );

        setSystems(newFilteredSystems);
      }
    } else {
      setSystems(allSystem);
    }
  };

  const handleResetFilters = () => {
    setFilters(defaultFilter);
    handleSearch(null, defaultFilter);
  };

  const handleChangePage = newPage => setPage(newPage);

  const actions = [
    {
      icon: <Add />,
      onClick: createNewSystem
    }
  ];

  const search = {
    renderFilters: () => <SystemFilter values={filters} onChange={handleFilterChanges} />,
    onSearch: handleSearch,
    onReset: handleResetFilters
  };

  const pageStartIndex = (page - 1) * pageSize;
  const pageEndIndex = pageStartIndex + pageSize;

  return (
    <Page
      className="table system-list-page"
      titleId="systemList.title"
      actions={actions}
      search={search}
    >
      <div className="table-body system-list">
        <div className="heads">
          <div>{/* Expand column placeholder */}</div>
          {fields.map(field => (
            <div
              key={field}
              role="button"
              tabIndex={0}
              className="head"
              onClick={() => sortBy(field)}
            >
              <FormattedMessage id={`systemList.${field}`} />
              {sort.field === field ? (
                <Sort className={classnames({ flipped: sort.direction === 1 })} />
              ) : (
                <div className="sort-placeholder"></div>
              )}
            </div>
          ))}
          <div>{/* Actions column placeholder */}</div>
        </div>

        {systems
          .sort((a, b) => sort.direction * a[sort.field].localeCompare(b[sort.field]))
          .slice(pageStartIndex, pageEndIndex)
          .map(system => (
            <SystemTableRow
              key={system.id}
              system={system}
              onEdit={() => editSystem(system)}
              onDelete={() => openConfirmationModal(system.id)}
            />
          ))}
      </div>

      <Paginator
        pages={Math.ceil(systems.length / pageSize)}
        currentPage={page}
        onChange={handleChangePage}
      />

      {systemForm && (
        <SystemDialog
          title={formatMessage({
            id: systemForm.id ? 'updatesystemDialog.title' : 'createSystemDialog.title'
          })}
          form={systemForm}
          formInvoice={systemForm.invoice}
          isEdit={!!systemForm.id}
          onSave={save}
          onClose={closeModal}
        />
      )}

      <ConfirmationDialog
        open={confirmationDialog.visible}
        title={formatMessage({ id: 'systemList.confirmDelete.title' })}
        text={formatMessage(
          { id: 'systemList.confirmDelete.text' },
          { system: confirmationDialog.selectedId }
        )}
        actions={[
          {
            name: 'delete',
            onClick: () => deleteSystemById(confirmationDialog.selectedId),
            className: 'primary-button'
          },
          {
            name: 'cancel',
            onClick: () => setConfirmationDialog({ visible: false }),
            className: 'secondary-button'
          }
        ]}
        onClose={() => setConfirmationDialog({ visible: false })}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snackbar.visible}
        message={snackbar.text}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ visible: false, text: '' })}
      />
    </Page>
  );
};

export default SystemList;
