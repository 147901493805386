import { useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import log from 'loglevel';

import ClosureDetailsForm from './Form/ClosureDetailsForm';
import Loading from '../Loading';
import ConfirmationDialog from '../Modals/ConfirmationDialog';

import { updateSystemClosure } from '../../rest/resource/closure';

import { DEFAULT_CURRENCY, LOAD_STATUS } from '../constants';
import { delay } from '../delay';

const UPDATEABLE_FIELDS = ['note', 'moneyTransferId', 'invoiceUrl', 'status'];
const CLOSURE_STATUSES = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED'
};

const logger = log.getLogger('SystemClosureDetails');

const SystemClosureDetails = ({ systemClosure }) => {
  const { formatMessage } = useIntl();

  const [systemClosureDetails, setSystemClosureDetails] = useState(systemClosure);
  const [updateStatus, setUpdateStatus] = useState(LOAD_STATUS.SUCCESS);

  const isClosed = systemClosureDetails.status === CLOSURE_STATUSES.CLOSED;

  useEffect(() => {
    setSystemClosureDetails(systemClosure);
  }, [systemClosure]);

  const handleInputChange = (field, value) => {
    setSystemClosureDetails(prevState => ({ ...prevState, [field]: value }));
  };

  const _getUpdateableFields = () =>
    UPDATEABLE_FIELDS.reduce((result, current) => {
      result[current] = systemClosureDetails[current];
      return result;
    }, {});

  const handleSystemClosureUpdate = async updatedFields => {
    try {
      setUpdateStatus(LOAD_STATUS.LOADING);

      const updatedClosure = await updateSystemClosure(
        systemClosureDetails.closureId,
        systemClosureDetails.systemId,
        updatedFields
      );

      await delay();

      setSystemClosureDetails({ ...systemClosureDetails, ...updatedClosure });
      setUpdateStatus(LOAD_STATUS.SUCCESS);
    } catch (err) {
      logger.error('Failed to update system closure.', err);
      setUpdateStatus(LOAD_STATUS.ERROR);
    }
  };

  const handleSaveClosure = () => {
    handleSystemClosureUpdate(_getUpdateableFields());
  };

  const handleSaveAndCloseClosure = () => {
    const updateableFields = _getUpdateableFields();
    const updatedFields = { ...updateableFields, status: CLOSURE_STATUSES.CLOSED };
    handleSystemClosureUpdate(updatedFields);
  };

  const handleReopenClosure = () => {
    const updateableFields = _getUpdateableFields();
    const updatedFields = { ...updateableFields, status: CLOSURE_STATUSES.OPEN };
    handleSystemClosureUpdate(updatedFields);
  };

  const handleUpdateErrorModalClose = () => {
    setUpdateStatus(LOAD_STATUS.SUCCESS);
  };

  const formItems = [
    {
      title: formatMessage({ id: 'accounting.closure.system.details.total' }),
      renderValue: () => (
        <FormattedNumber
          value={systemClosureDetails.total}
          // eslint-disable-next-line react/style-prop-object
          style="currency"
          currency={systemClosureDetails.currency || DEFAULT_CURRENCY}
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      )
    },
    {
      title: formatMessage({ id: 'accounting.closure.system.details.status' }),
      renderValue: () => (
        <span className="system-closure-close">
          <FormattedMessage
            id={`accounting.closure.system.details.statuses.${systemClosureDetails.status}`}
          />
        </span>
      )
    },

    {
      title: formatMessage({ id: 'accounting.closure.system.details.moneyTransferId' }),
      renderValue: () => (
        <TextField
          value={systemClosureDetails.moneyTransferId || ''}
          onChange={event => handleInputChange('moneyTransferId', event.target.value)}
          disabled={isClosed}
        />
      )
    },
    {
      title: formatMessage({ id: 'accounting.closure.system.details.invoiceUrl' }),
      renderValue: () => (
        <TextField
          value={systemClosureDetails.invoiceUrl || ''}
          onChange={event => handleInputChange('invoiceUrl', event.target.value)}
          disabled={isClosed}
        />
      )
    },
    {
      title: formatMessage({ id: 'accounting.closure.system.details.note' }),
      renderValue: () => (
        <TextField
          value={systemClosureDetails.note || ''}
          onChange={event => handleInputChange('note', event.target.value)}
          multiline
          rows={4}
          disabled={isClosed}
        />
      )
    }
  ];

  const actions = isClosed
    ? [{ name: 'reopen', onClick: handleReopenClosure }]
    : [
        { name: 'save', onClick: handleSaveClosure },
        { name: 'saveAndClose', onClick: handleSaveAndCloseClosure }
      ];

  const renderUpdateState = () => {
    switch (updateStatus) {
      case LOAD_STATUS.LOADING:
        return <Loading />;
      case LOAD_STATUS.ERROR:
        return (
          <ConfirmationDialog
            open
            onClose={handleUpdateErrorModalClose}
            title={formatMessage({ id: 'accounting.closure.details.updateFailed.modal.title' })}
            text={formatMessage({ id: 'accounting.closure.details.updateFailed.modal.message' })}
            actions={[
              {
                name: 'confirm',
                onClick: handleUpdateErrorModalClose,
                className: 'primary-button'
              }
            ]}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <ClosureDetailsForm
        title={formatMessage({ id: 'accounting.closure.details.title' })}
        items={formItems}
        actions={actions}
      />
      {renderUpdateState()}
    </>
  );
};

export default SystemClosureDetails;
