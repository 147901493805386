import React, { useState } from 'react';
import { IconButton, Tooltip, Snackbar, Alert } from '@mui/material';
import { Edit, DeleteOutline, ContentCopy, ExpandMore, ExpandLess } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import SystemTableRowDetails from './SystemTableRowDetails';

const keyOrder = ['id', 'name', 'description', 'url', 'apiKey'];

const SystemTableRow = ({ system, onEdit, onDelete }) => {
  const { formatMessage } = useIntl();

  const [snackbar, setSnackbar] = useState({ visible: false });
  const [detailsOpen, setDetailsOpen] = useState(false);

  const handleCloseSnackbar = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ visible: false });
  };

  const handleTextCopied = () =>
    setSnackbar({ visible: true, text: formatMessage({ id: 'system.copied' }) });

  const handleToggleExpand = () => setDetailsOpen(prev => !prev);

  return (
    <>
      <div className="table-content system-list-item">
        <IconButton className="expand-button" disableRipple onClick={handleToggleExpand}>
          {detailsOpen ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        {keyOrder.map(key => (
          <div key={key} className="field-data">
            {(key === 'id' || key === 'apiKey') && (
              <span>
                <CopyToClipboard text={system[key]} onCopy={handleTextCopied}>
                  <Tooltip title={formatMessage({ id: 'system.copy' })}>
                    <IconButton className="copy-button" disableRipple>
                      <ContentCopy />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </span>
            )}
            <span>{system[key]}</span>
            <Snackbar open={snackbar.visible} autoHideDuration={2000} onClose={handleCloseSnackbar}>
              <Alert severity="success" onClose={handleCloseSnackbar}>
                {snackbar.text}
              </Alert>
            </Snackbar>
          </div>
        ))}
        <div className="actions">
          <IconButton onClick={onEdit}>
            <Edit />
          </IconButton>
          <IconButton onClick={onDelete}>
            <DeleteOutline />
          </IconButton>
        </div>
      </div>
      {detailsOpen && <SystemTableRowDetails system={system} />}
    </>
  );
};

export default SystemTableRow;
