import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import log from 'loglevel';

import Page from '../Layout/Page';
import SystemClosureDetails from '../../components/AccountingClosure/SystemClosureDetails';
import SystemClosureTransactionList from '../../components/AccountingClosure/SystemClosureTransactionList';
import Loading from '../../components/Loading';
import { LOAD_STATUS } from '../../components/constants';

import {
  downloadCsvExport,
  downloadPdfExport,
  getClosureBySystem
} from '../../rest/resource/closure';

import { ReactComponent as CsvIcon } from '../../assets/img/csv_icon.svg';
import { ReactComponent as PdfIcon } from '../../assets/img/pdf_icon.svg';

const logger = log.getLogger('AccountingSystemClosure');

const AccountingSystemClosure = () => {
  const { formatMessage, formatDate, locale } = useIntl();
  const { id: closureId, systemId } = useParams();

  const [loadStatus, setLoadStatus] = useState(LOAD_STATUS.LOADING);
  const [systemClosure, setSystemClosure] = useState();

  useEffect(() => {
    (async () => {
      try {
        const systemClosureResponse = await getClosureBySystem(closureId, systemId);
        setSystemClosure(systemClosureResponse);
        setLoadStatus(LOAD_STATUS.SUCCESS);
      } catch (err) {
        logger.error('Failed to get system closure.', err);
        setLoadStatus(LOAD_STATUS.ERROR);
      }
    })();
  }, [closureId, systemId]);

  const pageTitle = useMemo(
    () =>
      systemClosure
        ? `${formatMessage({ id: 'accounting.closure.title' })} - 
    ${formatDate(systemClosure.closureDate)} - 
    ${systemClosure.systemName}`
        : formatMessage({ id: 'accounting.closure.title' }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [systemClosure, locale]
  );

  const breadcrumbs = systemClosure
    ? [
        { label: formatMessage({ id: 'accounting.closure.title' }), ref: '/accounting/closure' },
        { label: formatDate(systemClosure.closureDate), ref: `/accounting/closure/${closureId}` },
        { label: systemClosure.systemName, ref: `/accounting/closure/${closureId}/${systemId}` }
      ]
    : [{ label: formatMessage({ id: 'accounting.closure.title' }), ref: '/accounting/closure' }];

  const actions = [
    {
      icon: <PdfIcon className="pdf-export-icon" />,
      onClick: () => downloadPdfExport(closureId, systemId),
      className: 'pdf-export',
      title: formatMessage({ id: 'accounting.closure.system.export.pdf' })
    },
    {
      icon: <CsvIcon className="csv-export-icon" />,
      onClick: () => downloadCsvExport(closureId, systemId),
      className: 'csv-export',
      title: formatMessage({ id: 'accounting.closure.system.export.csv' })
    }
  ];

  const renderContent = () => {
    switch (loadStatus) {
      case LOAD_STATUS.LOADING:
        return <Loading />;
      case LOAD_STATUS.SUCCESS: {
        const { transactions, ...details } = systemClosure;
        return (
          <>
            <SystemClosureDetails
              systemClosure={{
                ...details,
                closureId,
                systemId,
                currency: transactions[0]?.currency
              }}
            />
            <SystemClosureTransactionList transactions={transactions} />
          </>
        );
      }
      default:
        return (
          <div className="closure-list-message">
            <FormattedMessage id="accounting.closure.system.error" />
          </div>
        );
    }
  };

  return (
    <Page
      className="closure-page"
      title={pageTitle}
      breadcrumbs={breadcrumbs}
      actions={actions}
      back
    >
      {renderContent()}
    </Page>
  );
};

export default AccountingSystemClosure;
