import { api } from '../rest';

const createSystem = async system => (await api.post('/system', system)).data;

const getSystems = async () => (await api.get('/system')).data;

const getSystemById = async systemId => (await api.get(`/system/${systemId}`)).data;

const updateSystem = async (systemId, system) =>
  (await api.put(`/system/${systemId}`, system)).data;

const deleteSystem = async systemId => (await api.delete(`/system/${systemId}`)).data;

export { createSystem, getSystems, getSystemById, updateSystem, deleteSystem };
