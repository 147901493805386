import Flag from 'react-flagpack';

const CircularFlag = ({ code, ...props }) => (
  <div className="flag-border" {...props}>
    <div className="flag-sizer">
      <Flag code={code} size="M" />
    </div>
  </div>
);

export default CircularFlag;
