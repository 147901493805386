import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import log from 'loglevel';

import DatePicker from '../DatePicker';
import { formatDate } from '../DateFormatter';

import { getSystems } from '../../rest/resource/systems';

const STATUSES = ['RECORDED', 'IMPORTED', 'CLOSED'];

const logger = log.getLogger('TransactionFilters');

const TransactionFilters = ({ filters, setFilters }) => {
  const { formatMessage } = useIntl();

  const [systemMenu, setSystemMenu] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const systemsResponse = await getSystems();
        const systems = systemsResponse.map(system => ({ id: system.id, name: system.name }));
        systems.unshift({ id: 'all', name: 'systemAll' });
        setSystemMenu(systems);
      } catch (err) {
        logger.error('Failed to get systems.', err);
      }
    })();
  }, []);

  const handleFilterChange = ({ target: { name, value } }) => {
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleStartDateChange = value => {
    setFilters(prev => {
      const formattedDate = formatDate(value);
      const updatedFilters = { ...prev, startDate: formattedDate };
      if (filters.endDate && filters.endDate < formattedDate) {
        updatedFilters.endDate = null;
      }
      return updatedFilters;
    });
  };

  const handleEndDateChange = value => {
    setFilters(prev => {
      const formattedDate = formatDate(value);
      const updatedFilters = { ...prev, endDate: formattedDate };
      if (filters.startDate && filters.startDate > formattedDate) {
        updatedFilters.startDate = null;
      }
      return updatedFilters;
    });
  };

  return (
    <>
      {systemMenu.length > 0 && (
        <div className="filter transactions-select">
          <span className="label">
            <FormattedMessage id="accounting.transactions.filter.system" />
          </span>
          <Select
            className="small-input"
            variant="outlined"
            name="system"
            value={filters.system}
            onChange={handleFilterChange}
          >
            {systemMenu.map(system => (
              <MenuItem className="menu-item" key={system.name} value={system.id}>
                <FormattedMessage
                  id={`accounting.transactions.filter.${system.name}`}
                  defaultMessage={system.name}
                />
              </MenuItem>
            ))}
          </Select>
        </div>
      )}

      <div className="filter transactions-date-picker">
        <span className="label">
          <FormattedMessage id="accounting.transactions.filter.date" />
        </span>
        <DatePicker
          value={filters.startDate}
          onChange={handleStartDateChange}
          placeholder={formatMessage({ id: 'accounting.transactions.filter.startDate' })}
        />
        -
        <DatePicker
          value={filters.endDate}
          onChange={handleEndDateChange}
          placeholder={formatMessage({ id: 'accounting.transactions.filter.endDate' })}
        />
      </div>

      <div className="filter transactions-select">
        <span className="label">
          <FormattedMessage id="accounting.transactions.filter.status" />
        </span>
        <Select
          className="small-input"
          multiple
          name="status"
          value={filters.status}
          onChange={handleFilterChange}
          input={<OutlinedInput />}
          renderValue={selected =>
            selected
              .map(item => formatMessage({ id: `accounting.transactions.statuses.${item}` }))
              .join(', ')
          }
        >
          {STATUSES.map(status => (
            <MenuItem key={status} value={status}>
              <Checkbox checked={filters.status.includes(status)} />
              <ListItemText
                primary={<FormattedMessage id={`accounting.transactions.statuses.${status}`} />}
              />
            </MenuItem>
          ))}
        </Select>
      </div>
    </>
  );
};

export default TransactionFilters;
