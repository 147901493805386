import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import HeaderMenu from './HeaderMenu';

const AccountingMenuItem = ({ id }) => (
  <Link to={`/accounting/${id}`}>
    <FormattedMessage id={`menu.accounting.${id}`} />
  </Link>
);

const AccountingMenu = () => {
  const menuItems = [
    {
      element: <AccountingMenuItem id="import" />
    },
    {
      element: <AccountingMenuItem id="transactions" />
    },
    {
      element: <AccountingMenuItem id="closure" />
    }
  ];

  const renderTitle = () => <FormattedMessage id="menu.accounting.title" />;

  return <HeaderMenu renderTitle={renderTitle} menuItems={menuItems} />;
};

export default AccountingMenu;
