import fileDownload from 'js-file-download';
import { api } from '../rest';

const filenameRegex = /filename\s*=\s*"(.+)"/i;

const getClosures = async () => (await api.get('/accounting/closure')).data;

const getClosure = async id => (await api.get(`/accounting/closure/${id}`)).data;

const startClosure = async closure => (await api.post('/accounting/closure', closure)).data;

const updateClosure = async (id, closureUpdate) =>
  (await api.patch(`/accounting/closure/${id}`, closureUpdate)).data;

const getClosureBySystem = async (closureId, systemId) =>
  (await api.get(`/accounting/closure/${closureId}/system/${systemId}`)).data;

const updateSystemClosure = async (closureId, systemId, updatedSystemClosure) =>
  (await api.patch(`/accounting/closure/${closureId}/system/${systemId}`, updatedSystemClosure))
    .data;

const downloadFile = async path => {
  const response = await api.get(path, { responseType: 'blob' });

  const contentDisposition = response.headers['content-disposition'];
  const match = contentDisposition.match(filenameRegex);
  const filename = match[1];

  fileDownload(response.data, filename);
};

const downloadCsvExport = (closureId, systemId) =>
  downloadFile(`/accounting/closure/${closureId}/system/${systemId}/csv`);

const downloadPdfExport = (closureId, systemId) =>
  downloadFile(`/accounting/closure/${closureId}/system/${systemId}/pdf`);

export {
  getClosures,
  getClosure,
  startClosure,
  updateClosure,
  getClosureBySystem,
  updateSystemClosure,
  downloadCsvExport,
  downloadPdfExport
};
