import { FormattedMessage } from 'react-intl';
import { FilterAlt } from '@mui/icons-material';
import { Button } from '@mui/material';

const SearchBar = ({ renderFilters, onSearch, onReset }) => (
  <div className="search-bar">
    <FilterAlt />
    <div className="filters">{renderFilters()}</div>
    <div className="actions">
      <Button variant="contained" className="primary-button small-button" onClick={onSearch}>
        <FormattedMessage id="common.buttons.apply" />
      </Button>
      <Button variant="contained" className="secondary-button small-button" onClick={onReset}>
        <FormattedMessage id="common.buttons.reset" />
      </Button>
    </div>
  </div>
);

export default SearchBar;
