import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import log from 'loglevel';

import Page from '../Layout/Page';
import ClosureList from '../../components/AccountingClosure/ClosureList';
import ClosureCreateForm from '../../components/AccountingClosure/ClosureCreateForm';
import Loading from '../../components/Loading';
import ConfirmationDialog from '../../components/Modals/ConfirmationDialog';

import { LOAD_STATUS } from '../../components/constants';

import { startClosure } from '../../rest/resource/closure';

const logger = log.getLogger('AccountingClosure');

const AccountingClosure = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [startClosureStatus, setStartClosureStatus] = useState();

  const handleStartClosure = async data => {
    setStartClosureStatus(LOAD_STATUS.LOADING);
    try {
      const newClosure = await startClosure(data);
      setStartClosureStatus(LOAD_STATUS.SUCCESS);
      navigate(`/accounting/closure/${newClosure.id}`);
    } catch (err) {
      logger.error('Failed to start closure.', err);
      setStartClosureStatus(LOAD_STATUS.ERROR);
    }
  };

  const handleStartErrorModalClose = () => {
    setStartClosureStatus(null);
  };

  const renderClosureStartState = () => {
    switch (startClosureStatus) {
      case LOAD_STATUS.LOADING:
        return <Loading />;
      case LOAD_STATUS.ERROR:
        return (
          <ConfirmationDialog
            open
            onClose={handleStartErrorModalClose}
            title={formatMessage({ id: 'accounting.closure.createFailed.modal.title' })}
            text={formatMessage({ id: 'accounting.closure.createFailed.modal.message' })}
            actions={[
              {
                name: 'confirm',
                onClick: handleStartErrorModalClose,
                className: 'primary-button'
              }
            ]}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Page className="closure-page" titleId="accounting.closure.title" back>
      <ClosureCreateForm onConfirm={handleStartClosure} />
      <ClosureList />
      {renderClosureStartState()}
    </Page>
  );
};

export default AccountingClosure;
