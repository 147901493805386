import { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, FormattedTime } from 'react-intl';
import { Sort } from '@mui/icons-material';
import log from 'loglevel';
import classnames from 'classnames';

import Page from '../Layout/Page';
import Loading from '../../components/Loading';
import TransactionFilters from '../../components/AccountingTransactions/TransactionsFilter';
import Paginator from '../../components/Paginator';

import { getTransactions } from '../../rest/resource/transactions';
import { DEFAULT_CURRENCY } from '../../components/constants';

const LOAD_STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error'
};

const FIELDS = ['id', 'paymentDate', 'systemName', 'total', 'status'];
const PAGE_SIZE = 10;
const DEFAULT_SORT = { field: 'paymentDate', direction: -1 };

const DEFAULT_FILTERS = {
  system: 'all',
  startDate: null,
  endDate: null,
  status: []
};

const logger = log.getLogger('AccountingTransactions');

const AccountingTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loadStatus, setLoadStatus] = useState(LOAD_STATUS.LOADING);
  const [editableFilters, setEditableFilters] = useState(DEFAULT_FILTERS);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [sort, setSort] = useState(DEFAULT_SORT);
  const [page, setPage] = useState(1);
  const [allPages, setAllPages] = useState(1);

  const searchTransactionsPage = async currentPage => {
    try {
      setPage(currentPage);
      const validatedFilters = Object.entries(filters).reduce((result, [key, value]) => {
        result[key] = value === 'all' ? null : value;
        return result;
      }, {});
      const transactionsResponse = await getTransactions({
        page: currentPage,
        pageSize: PAGE_SIZE,
        sortBy: sort.field,
        direction: sort.direction > 0 ? 'ASC' : 'DESC',
        ...validatedFilters
      });
      setTransactions(transactionsResponse.items);
      setAllPages(Math.ceil(transactionsResponse.total / PAGE_SIZE));
      setLoadStatus(LOAD_STATUS.SUCCESS);
    } catch (err) {
      logger.error('Failed to get transactions.', err);
      setLoadStatus(LOAD_STATUS.ERROR);
    }
  };

  useEffect(() => {
    searchTransactionsPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sort]);

  const sortBy = field => {
    setSort(prev => {
      if (prev.field === field) {
        return { ...prev, direction: -prev.direction };
      }

      return { field, direction: 1 };
    });
  };

  const handleSearch = () => {
    setFilters({ ...editableFilters });
  };

  const handleResetFilters = () => {
    setFilters({ ...DEFAULT_FILTERS });
    setEditableFilters({ ...DEFAULT_FILTERS });
  };

  const handleChangePage = newPage => {
    searchTransactionsPage(newPage);
  };

  const search = {
    renderFilters: () => (
      <TransactionFilters filters={editableFilters} setFilters={setEditableFilters} />
    ),
    onSearch: handleSearch,
    onReset: handleResetFilters
  };

  const renderField = (field, transaction) => {
    switch (field) {
      case 'paymentDate':
        return (
          <span key={field}>
            <FormattedDate value={transaction[field]} />{' '}
            <FormattedTime value={transaction[field]} />
          </span>
        );
      case 'status':
        return (
          <div key={field} className="field-data">
            <FormattedMessage id={`accounting.transactions.statuses.${transaction[field]}`} />
            {transaction[field] === 'RECORDED' && (
              <>
                {' '}
                (
                <FormattedMessage
                  id={`accounting.transactions.statuses.${transaction.transactionStatus}`}
                />
                )
              </>
            )}
          </div>
        );
      case 'total':
        return (
          <div key={field} className="field-data amount">
            {transaction[field] === null ? (
              <span>-</span>
            ) : (
              <FormattedNumber
                value={transaction[field]}
                // eslint-disable-next-line react/style-prop-object
                style="currency"
                currency={transaction.currency || DEFAULT_CURRENCY}
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
            )}
          </div>
        );
      default:
        return (
          <div key={field} className="field-data">
            <span title={transaction[field]}>{transaction[field]}</span>
          </div>
        );
    }
  };

  const renderTransaction = transaction => (
    <div className="table-content transaction-item" key={transaction.id}>
      {FIELDS.map(field => renderField(field, transaction))}
    </div>
  );

  const renderTableHeads = () => (
    <div className="table-heads">
      {FIELDS.map(field => {
        if (field === 'status') {
          return (
            <div key={field} className={`table-head-${field}`}>
              <div className="table-head">
                <FormattedMessage id={`accounting.transactions.${field}`} />
                <div className="sort-placeholder"></div>
              </div>
            </div>
          );
        }
        return (
          <div key={field} className={`table-head-${field}`}>
            <div role="button" tabIndex={0} className="table-head" onClick={() => sortBy(field)}>
              <FormattedMessage id={`accounting.transactions.${field}`} />
              {sort.field === field ? (
                <Sort className={classnames({ flipped: sort.direction === 1 })} />
              ) : (
                <div className="sort-placeholder"></div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );

  const renderTransactionsTable = () => (
    <div className="table">
      <div className="table-body transaction-list">
        {renderTableHeads()}
        {transactions.map(renderTransaction)}
      </div>

      <Paginator pages={allPages} currentPage={page} onChange={handleChangePage} />
    </div>
  );

  const renderTransactions = () => {
    switch (loadStatus) {
      case LOAD_STATUS.LOADING:
        return <Loading />;
      case LOAD_STATUS.SUCCESS:
        return transactions.length ? (
          renderTransactionsTable()
        ) : (
          <div className="transaction-list-message">
            <FormattedMessage id="accounting.transactions.empty" />
          </div>
        );
      default:
        return (
          <div className="transaction-list-message">
            <FormattedMessage id="accounting.transactions.error" />
          </div>
        );
    }
  };

  return (
    <Page
      className="transaction-list-page"
      titleId="accounting.transactions.title"
      search={search}
      back
    >
      {renderTransactions()}
    </Page>
  );
};

export default AccountingTransactions;
