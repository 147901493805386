import { useContext } from 'react';
import { IconButton } from '@mui/material';

import CircularFlag from '../CircularFlag';

import { AppContext } from '../../context/AppContextProvider';
import HeaderMenu from './HeaderMenu';

const localeFlagMapping = {
  hu: 'HU',
  en: 'GB-UKM'
};

const LanguageSelector = () => {
  const { locale, availableLocales, changeLocale } = useContext(AppContext);

  const handleLanguageSelect = language => {
    changeLocale(language);
  };

  const options = availableLocales.filter(l => l !== locale);

  const renderTitle = () => (
    <IconButton disableRipple>
      <CircularFlag code={localeFlagMapping[locale]} />
    </IconButton>
  );

  const menuItems = options.map(language => ({
    element: (
      <CircularFlag
        key={language}
        code={localeFlagMapping[language]}
        onClick={() => handleLanguageSelect(language)}
      />
    )
  }));

  return <HeaderMenu renderTitle={renderTitle} menuItems={menuItems} hideExpandIcon />;
};

export default LanguageSelector;
