import { useAuth } from 'react-oidc-context';
import { FormattedMessage } from 'react-intl';
import { AccountCircle } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import HeaderMenu from './HeaderMenu';

const UserMenuHead = ({ onClick }) => (
  <IconButton className="profile-button" disableRipple onClick={onClick}>
    <AccountCircle />
  </IconButton>
);

const UserMenu = () => {
  const auth = useAuth();

  const logout = () => {
    auth.signoutRedirect();
  };

  const menuItems = [
    {
      element: (
        <div role="button" tabIndex={0} onClick={logout}>
          <FormattedMessage id="app.logout" />
        </div>
      )
    }
  ];

  const renderTitle = () => <UserMenuHead />;

  return (
    <div className="user-menu">
      <HeaderMenu renderTitle={renderTitle} menuItems={menuItems} hideExpandIcon />
    </div>
  );
};

export default UserMenu;
