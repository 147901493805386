import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import FormSection from '../../FormSection';

const ClosureFormItem = ({ title, children }) => (
  <div className="closure-form-item">
    <span className="label">{title}</span>
    <span className="value">{children}</span>
  </div>
);

const ClosureDetailsForm = ({ title, items, actions }) => (
  <FormSection title={title} className="closure-details">
    {items.map((item, index) => (
      <ClosureFormItem key={index} title={item.title}>
        {item.renderValue()}
      </ClosureFormItem>
    ))}
    {actions.length > 0 && (
      <div className="form-actions-container">
        {actions.map(action => (
          <Button
            key={action.name}
            variant="contained"
            className="primary-button"
            onClick={action.onClick}
          >
            <FormattedMessage id={`accounting.closure.system.details.update.${action.name}`} />
          </Button>
        ))}
      </div>
    )}
  </FormSection>
);

ClosureDetailsForm.defaultProps = {
  actions: []
};

export default ClosureDetailsForm;
