import { FormattedMessage } from 'react-intl';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import classnames from 'classnames';

const ConfirmationDialog = ({ open, onClose, title, text, actions, className }) => (
  <Dialog className={classnames('dialog', className)} open={open} onClose={onClose}>
    <DialogTitle className="title">{title}</DialogTitle>
    <DialogContent className="text">{text}</DialogContent>
    <DialogActions className="actions">
      {actions.map(action => (
        <Button
          key={action.name}
          variant="contained"
          className={action.className}
          onClick={action.onClick}
        >
          <FormattedMessage id={`common.buttons.${action.name}`} />
        </Button>
      ))}
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;
