import { useOutlet } from 'react-router-dom';

import Header from '../Layout/Header';
import Main from '../Layout/Main';
import Footer from '../Layout/Footer';

import SystemContextProvider from '../../context/SystemContextProvider';

const RootLayout = () => {
  const outlet = useOutlet();

  return (
    <div id="root">
      <Header />
      <Main>
        <SystemContextProvider>{outlet}</SystemContextProvider>
      </Main>
      <Footer />
    </div>
  );
};

export default RootLayout;
