import { useEffect, useState } from 'react';
import { FormattedDate, FormattedNumber, FormattedTime, useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import log from 'loglevel';

import ConfirmationDialog from '../Modals/ConfirmationDialog';
import ClosureDetailsForm from './Form/ClosureDetailsForm';
import Loading from '../Loading';

import { updateClosure } from '../../rest/resource/closure';

import { DEFAULT_CURRENCY, LOAD_STATUS } from '../constants';
import { delay } from '../delay';

const logger = log.getLogger('ClosureDetails');

const ClosureDetails = ({ closure }) => {
  const { formatMessage } = useIntl();

  const [closureDetails, setClosureDetails] = useState(closure);
  const [updateStatus, setUpdateStatus] = useState(LOAD_STATUS.SUCCESS);

  useEffect(() => {
    setClosureDetails(closure);
  }, [closure]);

  const handleNoteInputChange = ({ target: { value } }) => {
    setClosureDetails(prevState => ({ ...prevState, note: value }));
  };

  const handleClosureUpdate = async () => {
    setUpdateStatus(LOAD_STATUS.LOADING);
    try {
      const updatedClosure = await updateClosure(closureDetails.id, { note: closureDetails.note });

      await delay();

      setClosureDetails({ ...closureDetails, note: updatedClosure.note });
      setUpdateStatus(LOAD_STATUS.SUCCESS);
    } catch (err) {
      logger.error('Failed to update closure.', err);
      setUpdateStatus(LOAD_STATUS.ERROR);
    }
  };

  const handleUpdateErrorModalClose = () => {
    setUpdateStatus(LOAD_STATUS.SUCCESS);
  };

  const renderUpdateState = () => {
    switch (updateStatus) {
      case LOAD_STATUS.LOADING:
        return <Loading />;
      case LOAD_STATUS.ERROR:
        return (
          <ConfirmationDialog
            open
            onClose={handleUpdateErrorModalClose}
            title={formatMessage({ id: 'accounting.closure.details.updateFailed.modal.title' })}
            text={formatMessage({ id: 'accounting.closure.details.updateFailed.modal.message' })}
            actions={[
              {
                name: 'confirm',
                onClick: handleUpdateErrorModalClose,
                className: 'primary-button'
              }
            ]}
          />
        );
      default:
        return null;
    }
  };

  const formItems = [
    {
      title: formatMessage({ id: `accounting.closure.details.closureDate` }),
      renderValue: () => <FormattedDate value={closureDetails.closureDate} />
    },
    {
      title: formatMessage({ id: `accounting.closure.details.username` }),
      renderValue: () => closureDetails.username
    },
    {
      title: formatMessage({ id: `accounting.closure.details.total` }),
      renderValue: () => (
        <FormattedNumber
          value={closureDetails.total}
          // eslint-disable-next-line react/style-prop-object
          style="currency"
          currency={closureDetails.currency || DEFAULT_CURRENCY}
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      )
    },
    {
      title: formatMessage({ id: `accounting.closure.details.creationDate` }),
      renderValue: () => (
        <>
          <FormattedDate value={closureDetails.creationDate} />{' '}
          <FormattedTime value={closureDetails.creationDate} />
        </>
      )
    },
    {
      title: formatMessage({ id: `accounting.closure.details.closureNote` }),
      renderValue: () => (
        <TextField value={closureDetails.note || ''} onChange={handleNoteInputChange} />
      )
    }
  ];

  const actions = [{ name: 'save', onClick: handleClosureUpdate }];

  return (
    <>
      <ClosureDetailsForm
        title={formatMessage({ id: 'accounting.closure.details.title' })}
        items={formItems}
        actions={actions}
      />
      {renderUpdateState()}
    </>
  );
};

export default ClosureDetails;
