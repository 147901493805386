import { IconButton, Tooltip } from '@mui/material';
import classnames from 'classnames';

const ActionButton = ({ title, icon, onClick, className }) => (
  <Tooltip title={title}>
    <IconButton className={classnames('action-button', className)} onClick={onClick}>
      {icon}
    </IconButton>
  </Tooltip>
);

export default ActionButton;
