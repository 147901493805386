import classnames from 'classnames';

const FormSection = ({ title, children, className }) => (
  <div className={classnames('form-section', className)}>
    <div className="section-title">{title}</div>
    <div className="section-content">{children}</div>
  </div>
);

export default FormSection;
