import { FormattedDate, FormattedMessage, FormattedNumber, FormattedTime } from 'react-intl';

import Table from '../Table';
import { DEFAULT_CURRENCY } from '../constants';

const FIELDS = [
  'id',
  'simplePayTransactionId',
  'transactionDate',
  'completionDate',
  'total',
  'invoiceId'
];

const PAGE_SIZE = 10;
const DEFAULT_SORT = { field: 'completionDate', direction: -1 };

const SystemClosureTransactionList = ({ transactions }) => {
  const renderField = (transaction, field) => {
    switch (field) {
      case 'completionDate':
      case 'transactionDate': {
        return (
          <span>
            <FormattedDate value={transaction[field]} />{' '}
            <FormattedTime value={transaction[field]} />
          </span>
        );
      }

      case 'total':
        return (
          <span className="amount">
            <FormattedNumber
              value={transaction[field]}
              // eslint-disable-next-line react/style-prop-object
              style="currency"
              currency={transaction.currency || DEFAULT_CURRENCY}
              minimumFractionDigits={0}
              maximumFractionDigits={0}
            />
          </span>
        );

      case 'invoiceId':
        return transaction[field] ? (
          <span title={transaction[field]}>{transaction[field]}</span>
        ) : (
          <span>-</span>
        );

      default:
        return <span title={transaction[field]}>{transaction[field]}</span>;
    }
  };

  const renderRow = transaction => (
    <div className="table-content" key={transaction.id}>
      {FIELDS.map(field => (
        <div key={field} className="field-data">
          {renderField(transaction, field)}
        </div>
      ))}
    </div>
  );

  return (
    <div className="system-closure-transaction-list-container">
      <div className="closure-list-title">
        <FormattedMessage id="accounting.closure.system.transactionList.title" />
      </div>
      {transactions.length > 0 ? (
        <Table
          fields={FIELDS}
          rows={transactions}
          renderRow={renderRow}
          translationKey="accounting.closure.system.transactionList"
          className="system-closure-transaction-list"
          pageSize={PAGE_SIZE}
          defaultSort={DEFAULT_SORT}
        />
      ) : (
        <div className="closure-list-message">
          <FormattedMessage id="accounting.closure.system.transactionList.empty" />
        </div>
      )}
    </div>
  );
};

SystemClosureTransactionList.defaultProps = {
  transactions: []
};

export default SystemClosureTransactionList;
