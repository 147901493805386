import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { IconButton } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import log from 'loglevel';

import Loading from '../Loading';
import Table from '../Table';

import { getClosures } from '../../rest/resource/closure';

import { LOAD_STATUS } from '../constants';

const FIELDS = ['closureDate', 'username', 'creationDate'];
const PAGE_SIZE = 10;
const DEFAULT_SORT = { field: 'closureDate', direction: -1 };

const logger = log.getLogger('ClosureList');

const ClosureList = () => {
  const navigate = useNavigate();
  const [closures, setClosures] = useState([]);
  const [loadStatus, setLoadStatus] = useState(LOAD_STATUS.LOADING);

  const loadClosures = async () => {
    try {
      const closuresResponse = await getClosures();
      setClosures(closuresResponse);
      setLoadStatus(LOAD_STATUS.SUCCESS);
    } catch (err) {
      logger.error('Failed to get closures.', err);
      setLoadStatus(LOAD_STATUS.ERROR);
    }
  };

  useEffect(() => {
    loadClosures();
  }, []);

  const handleGoToClosureDetails = id => {
    navigate(`/accounting/closure/${id}`);
  };

  const renderField = (closure, field) => {
    switch (field) {
      case 'closureDate':
        return (
          <span>
            <FormattedDate value={closure[field]} />
          </span>
        );
      case 'creationDate':
        return (
          <span>
            <FormattedDate value={closure[field]} /> <FormattedTime value={closure[field]} />
          </span>
        );
      default:
        return <span title={closure[field]}>{closure[field]}</span>;
    }
  };

  const renderRow = closure => (
    <div className="table-content" key={closure.id}>
      {FIELDS.map(field => (
        <div key={field} className="field-data">
          {renderField(closure, field)}
        </div>
      ))}
      <div className="actions">
        <IconButton onClick={() => handleGoToClosureDetails(closure.id)}>
          <Visibility />
        </IconButton>
      </div>
    </div>
  );

  const renderClosures = () => {
    switch (loadStatus) {
      case LOAD_STATUS.LOADING:
        return <Loading />;
      case LOAD_STATUS.SUCCESS:
        return closures.length ? (
          <Table
            fields={FIELDS}
            rows={closures}
            renderRow={renderRow}
            translationKey="accounting.closure.list"
            className="closure-list"
            pageSize={PAGE_SIZE}
            defaultSort={DEFAULT_SORT}
          />
        ) : (
          <div className="closure-list-message">
            <FormattedMessage id="accounting.closure.list.empty" />
          </div>
        );
      default:
        return (
          <div className="closure-list-message">
            <FormattedMessage id="accounting.closure.list.error" />
          </div>
        );
    }
  };

  return (
    <div className="closure-list-container">
      <div className="closure-list-title">
        <FormattedMessage id="accounting.closure.list.title" />
      </div>
      {renderClosures()}
    </div>
  );
};

export default ClosureList;
